<template>
  <div class="progress">
    <div class="number-text" :class="isMobile ? 'number-text-mobile' : ''">{{ renderValue }}</div>
    <div class="progress-bar" :class="getValueClass"></div>
  </div>
</template>

<script>
export default {
  name: 'PercentageBar',
  props: ['value'],
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
    numberValue () {
      return Number(this.value)
    },
    getValueClass () {
      const value = Number(this.value)
      if (value === 100) {
        return 'onehundred'
      } else if (value >= 95) {
        return 'ninety-half'
      } else if (value >= 90) {
        return 'ninety'
      } else if (value >= 85) {
        return 'eighty-half'
      } else if (value >= 80) {
        return 'eighty'
      } else if (value >= 75) {
        return 'seventy-half'
      } else if (value >= 70) {
        return 'seventy'
      } else if (value >= 65) {
        return 'sixty-half'
      } else if (value >= 60) {
        return 'sixty'
      } else if (value >= 55) {
        return 'fifty-half'
      } else if (value >= 50) {
        return 'fifty'
      } else if (value >= 45) {
        return 'fourty-half'
      } else {
        return 'fourty'
      } 
    },
    renderValue () {
      return this.value + '%'
    }
  }
}
</script>

<style scoped>

.progress {
  margin: 0 auto;
  /* margin-left: -3%; */
  width: 100%;
  position: relative;
}

.progress {
  padding: 4px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.number-text {
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 79%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #0D1B2A;
}

.number-text-mobile {
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 79%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #0D1B2A;
}

.progress-bar {
  height: 30px;
  border-radius: 4px;
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25),
    inset 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25),
    inset 0 1px rgba(255, 255, 255, 0.1);
}

/*
 * Note: using adjacent or general sibling selectors combined with
 *       pseudo classes doesn't work in Safari 5.0 and Chrome 12.
 *       See this article for more info and a potential fix:
 *       https://css-tricks.com/webkit-sibling-bug/
 */

.zero {
  width: 5%;
  background-color: #f21111;
}

.ten {
  width: 10%;
  background-color: #f21111;
}

.ten-half {
  width: 15%;
  background-color: #f21111;
}

.twenty {
  width: 20%;
  background-color: #f23711;
}

.twenty-half {
  width: 25%;
  background-color: #f23711;
}

.thirty {
  width: 30%;
  background-color: #f25c11;
}

.thirty-half {
  width: 35%;
  background-color: #f25c11;
}

.fourty {
  width: 40%;
  background-color: #f28211;
}

.fourty-half {
  width: 45%;
  background-color: #f28211;
}

.fifty {
  width: 50%;
  background-color: #f2a711;
}

.fifty-half {
  width: 55%;
  background-color: #f2a711;
}

.sixty {
  width: 60%;
  background-color: #f2cc11;
}

.sixty-half {
  width: 65%;
  background-color: #f2cc11;
}

.seventy {
  width: 70%;
  background-color: #f2f211;
}

.seventy-half {
  width: 75%;
  background-color: #f2f211;
}

.eighty {
  width: 80%;
  background-color: #cdf211;
}

.eighty-half {
  width: 85%;
  background-color: #cdf211;
}

.ninety {
  width: 90%;
  background-color: #a7f211;
}

.ninety-half {
  width: 95%;
  background-color: #a7f211;
}

.onehundred {
  width: 100% !important;
  background-color: #82f211 !important;
}

</style>
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mb-10",class:{'px-16': !_vm.isMobile, 'pt-10': !_vm.isMobile, 'pt-0': _vm.isMobile, 'pb-3': _vm.isMobile, 'px-4': _vm.isMobile}},[_c(VRow,_vm._l((_vm.cardData),function(item,key){return _c(VCol,{key:key,class:_vm.isMobile ? 'pa-1': '',attrs:{"cols":_vm.isMobile ? 4 : 2}},[_c(VCard,{staticClass:"info-card my-2",attrs:{"max-width":_vm.isMobile ? 235 : 235,"color":"#415A77"}},[_c(VImg,{attrs:{"height":"100%","width":"100%","src":item.img}}),_c(VCardText,[_c(VRow,{staticClass:"mx-0",attrs:{"align":"center"}},[_c('PercentageBar',{attrs:{"value":item.percent}})],1),_c('div',{staticClass:"mt-7"},[_c(VListItemTitle,{staticClass:"info-card mb-1",class:_vm.isMobile ? 'info-card-mobile' : ''},[_vm._v(" JAM GACOR ")]),_c(VChip,{staticClass:"grey darken-3 info-card",class:_vm.isMobile ? 'info-card-mobile' : ''},[_vm._v(_vm._s(_vm.genTime()))])],1)],1),_c(VDivider,{staticClass:"mx-4"})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
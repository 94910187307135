<template>
  <div class="home-wrapper">
    <div class="home-content-wrapper pt-3">
      <section :class="isMobile ? 'brand-section-mobile' : 'brand-section'" class="pb-7">
        <v-row>
          <v-col :cols="isMobile ? 6 : 3">
            <a @click="onClickPage('/habanero')">
              <img class="game-brands-logo" :class="{'mb-0': !isMobile, 'mt-7': !isMobile, 'game-brands-logo-mobile': isMobile}" src="../assets/habanero.png" alt="">
            </a>
          </v-col>


          <v-col :cols="isMobile ? 6 : 3">
            <a @click="onClickPage('/pragmatic')">
              <img class="game-brands-logo" :class="{'mb-0': !isMobile, 'mt-7': !isMobile, 'game-brands-logo-mobile': isMobile}" src="../assets/pragmaticplay.png" alt="">
            </a>
          </v-col>

          <v-col :cols="isMobile ? 6 : 3">
            <a @click="onClickPage('/pgsoft')">
              <img class="game-brands-logo" :class="{'mb-0': !isMobile, 'mt-7': !isMobile, 'game-brands-logo-mobile': isMobile}" src="../assets/pgsoft.png" alt="">
            </a>
          </v-col>


          <v-col :cols="isMobile ? 6 : 3">
            <a @click="onClickPage('/spadegaming')">
              <img class="game-brands-logo" :class="{'mb-0': !isMobile, 'mt-7': !isMobile, 'game-brands-logo-mobile': isMobile}" src="../assets/spadegaming.png" alt="">
            </a>
          </v-col>
        </v-row>
      </section>
      <GameContent 
        :cardData="cardData"
        :isMobile="isMobile"
      />
    </div>

  </div>
</template>

<script>

import GameContent from '../components/GameContent.vue'
import pragmaticData from '../data/pragmatic.json'
import { genRandomPercent } from '../utils/getRandomPercent'

export default {
  name: 'HomePage',
  data () {
    return {
      isLoading: true,
      pragmaticData,
      genRandomPercent,
      cardData: []
    }
  },
  components: {
    GameContent,
  },
  created() {

    pragmaticData.table.map(e => {
      this.cardData.push(e)
      if (e.isPriority) e.percent = this.genRandomPercent(85, 99)
      else e.percent = this.genRandomPercent(40, 80)
    })

    const images = this.cardData.map(e => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = e.img;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(images).then(() => { 
      setTimeout(() => {
        this.isLoading = false;
      }, 0);
    }).catch(error => {
      console.error("Some image(s) failed loading!");
      console.error(error.message)
    });
  },
  mounted () {
    this.$nextTick(function () {
      window.setInterval(() => {
        this.cardData.map(e => {
          if (e.isPriority) {
            e.percent = this.genRandomPercent(85, 99)
          }
        })
      },30000);
    })
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  methods: {
    onClickPage (page) {
      this.$router.push(page).catch(()=>{})
    }
  }
}
</script>

<style scoped>
.home-wrapper {
  /* background-color: #444444; */
  color: #E0E1DD;
}

.brand-section {    
  display: flex;
  justify-content: space-evenly;
  /* border-bottom: white 1px solid; */
  margin: 0 8rem;
  text-align: center;
}


.brand-section-mobile {    
  margin: 0 1rem;
  text-align: center;
  /* border-bottom: white 1px solid; */
}

.hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

.game-brands-logo {
  width: 12rem;
  transition: transform 1s; /* Animation */
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  animation-timing-function: linear;
}

.active-brand {
  border: 1.2px solid #B666FF;
}

.game-brands-logo:hover {
  transform: scale(1.15);
}

.game-brands-logo-mobile {
  width: 6.5rem;
  transition: transform 1s; /* Animation */
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  animation-timing-function: linear;
}

</style>
<template>
  <section class="mb-10" :class="{'px-16': !isMobile, 'pt-10': !isMobile, 'pt-0': isMobile, 'pb-3': isMobile, 'px-4': isMobile}">
    <v-row>
      <v-col :cols="isMobile ? 4 : 2" :class="isMobile ? 'pa-1': ''" v-for="(item, key) in cardData" :key="key">
        <v-card
          class="info-card my-2"
          :max-width="isMobile ? 235 : 235"
          color="#415A77"
        >
          <v-img
            height="100%"
            width="100%"
            :src="item.img"
          ></v-img>
          <v-card-text>
            <v-row
              align="center"
              class="mx-0"
            >
            <PercentageBar :value="item.percent" />
            </v-row>
            <div class="mt-7">
              <v-list-item-title class="info-card mb-1" :class="isMobile ? 'info-card-mobile' : ''">
                JAM GACOR
              </v-list-item-title>
              <v-chip class="grey darken-3 info-card" :class="isMobile ? 'info-card-mobile' : ''">{{ genTime() }}</v-chip>
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>

        </v-card>
      </v-col>
    </v-row>

  </section>
</template>

<script>
import PercentageBar from './PercentageBar.vue'
import { genTime } from '../utils/genTime'
import { genRandomPercent } from '../utils/getRandomPercent'

export default {
  name: 'GameContent',
  components: {
    PercentageBar,
  },
  props: ['cardData', 'isMobile'],
  data () {
    return {
      genTime,
      genRandomPercent,
    }
  },
}
</script>

<style scoped>

.info-card {
  color: #E0E1DD;
}

.info-card-mobile {
  font-size: .7rem;
  font-weight: 600;
}

.v-card__text {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 13px 0 16px;
}

img, svg {
  vertical-align: middle;
}

</style>
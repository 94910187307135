export const genTime = () => {
  const randomDate = new Date(new Date(2012, 0, 1).getTime() + Math.random() * (new Date().getTime() - new Date(2012, 0, 1).getTime()));
  let startHour = new Date(randomDate).getHours()
  let startMinute = new Date(randomDate).getMinutes()
  let endHour = startHour + 1
  if (startHour.toString().length === 1) {
    startHour = '0' + startHour
    endHour = '0' + endHour
  }
  if(startMinute.toString().length === 1) {
    startMinute = '0' + startMinute
  }
  return `${startHour}:${startMinute} - ${endHour}:${startMinute}`
}